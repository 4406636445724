/* global window, document */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/html.css';

const scrollTo = id => () => {
  const el = document.querySelector(id);
  if (el)
    return window.scrollTo(
      0,
      el.offsetTop + (window.innerWidth > 768 ? 140 : 0)
    );
  return false;
};

const onRouteUpdate = _ref => {
  const { location } = _ref;
  const { hash } = location;
  if (hash) {
    window.setTimeout(scrollTo(hash), 10);
  }

  if (process.env.NODE_ENV !== 'production' || typeof gtag !== 'function') {
    return null;
  }

  const pathIsExcluded =
    location &&
    typeof window.excludeGtagPaths !== 'undefined' &&
    window.excludeGtagPaths.some(function(rx) {
      return rx.test(location.pathname);
    });
  if (pathIsExcluded) return null; // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)

  const sendPageView = function sendPageView() {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined;
    window.gtag('event', 'page_view', {
      page_path: pagePath,
    });
  };

  if ('requestAnimationFrame' in window) {
    requestAnimationFrame(function() {
      requestAnimationFrame(sendPageView);
    });
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32);
  }

  return null;
};

const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);
  const { hash } = location;
  if (hash) {
    return window.setTimeout(scrollTo(hash), 10);
  }
  window.scrollTo(...(currentPosition || [0, 0]));

  return false;
};

export { onRouteUpdate, shouldUpdateScroll };
